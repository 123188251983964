'use strict';

import $ from 'jquery';
import ScrollMagic from '../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import ScrollScene from '../scroll-scene/scroll-scene';

export default class LogoSection extends ScrollScene {

    constructor(controller) {

        var $background = $('.logo-section__background');
        var $logoContainer = $('.logo-container');

        super([
          new ScrollMagic.Scene({
            duration: 500,
            offset: 0
          })
            // .setPin('.logo-section')
            .on('progress', (event) => {
              let baseOpacity = 0.2;
              $logoContainer.fadeTo(0, 1 - (baseOpacity + (1 - baseOpacity) * event.progress))
            })
      ], controller);
    }
}
