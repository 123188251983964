'use strict';

import $ from 'jquery';
import Browser from '../../../node_modules/bowser/bowser';
import ScrollMagic from '../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import ScrollScene from '../scroll-scene/scroll-scene';
import MuteToggle from '../mute-toggle/mute-toggle';
import NaturalScroll from '../../../bower_components/natural-scroll/naturalScroll';

export default class Video extends ScrollScene {

    constructor(controller) {

      var $video = $('.video');
      var modifierClass = 'video--legibility';
      var muteToggle = new MuteToggle($video);

      super(new ScrollMagic.Scene({
              triggerElement: '.copy-section',
              duration: '100%',
          })
        .on('enter', (event) => {
          if (event.scrollDirection == 'FORWARD')
            $video.addClass(modifierClass);
        })
        .on('leave', (event) => {
          if (event.scrollDirection == 'REVERSE')
            $video.removeClass(modifierClass);
        })
        .on('progress', (event) => {
          let playbackRate = 1 - event.progress * 0.72,
              volume = Math.max(0, 1 - event.progress * 2);

          // Apply to video/s
          $video.each(function() {
            // Disable playback rate changes on Safari as this causes MAJOR slow down
            if (!Browser.safari)
              this.playbackRate = playbackRate;

            this.volume = volume;
          });

          // Fade out mute button
          muteToggle.fadeTo(volume);

        })
      , controller);

      // Listen for video ending first loop
      $video.on('playing', (event) => {
        $video
          .off('playing')
          $video.on('seeked', (event) => {
            if (event.target.currentTime <= 0.5 && window.scrollY <= 0) {
              // Scroll to target if still at top
              NaturalScroll.scrollTop(document.body, window.logoAnimationDuration, 1600);
            }
            // Remove seek event
            $video.off('seeked');
        });
      });

      // Clear video events as soon as any scroll detected
      var clearVideoAutoScroll = function() {
        $video.off('playing seeked');
        $(window).off('scroll', clearVideoAutoScroll);
      }
      $(window).on('scroll', clearVideoAutoScroll);

    }
}
