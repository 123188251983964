'use strict';

import $ from 'jquery';

export default class MuteToggle {

    constructor($video) {
    	// Save reference to element (as jQuery obj)
    	this.$el = $('.mute-toggle');
    	this.$video = $video;

    	// Listen for button actions
    	this.$el.on('change', (event) => {
    		if (this.$video) {
	    		let state = this.$el.find('.mute-toggle__input:checked').val();
    			this.$video.prop('muted', state == 'off');
    		}
    	});
    }

    fadeTo(opacity) {
    	this.$el.fadeTo(0, opacity);

    	if (opacity <= 0)
    		this.$el.hide();
    	else
    		this.$el.show();
    }

    set $video($video) {
    	this._$video = $video;
    }

    get $video() {
    	return this._$video;
    }
}
