'use strict';

import $ from 'jquery';

export default class CopySection {

    constructor(controller) {

      this.$copySections = $('.copy-section');

      this.adjustColumns();
      window.onresize = () => {
        this.adjustColumns();
      };

    }

    adjustColumns() {

      // Check whether height of first half of paragraphs is greater than second half on each copy section
      this.$copySections.each(function() {

        let $copySection = $(this),
            $paras = $copySection.find('p'),
            $article = $copySection.find('.copy-section__article'),
            numParas = $paras.length,
            halfNumParas = Math.ceil(numParas / 2),
            toggleClass = 'copy-section--inbalanced-column';

        let tallyHeight = function ($paras) {
            let height = 0;
            for (var p of $paras) {
              height += p.offsetHeight;
            }
            return height;
        }

        // Remove second spacer (should never be more than two)
        $copySection.find('.copy-section__p-spacer:eq(1)').remove();

        // Compare tally of first half vs last half
        let firstHalfHeight = tallyHeight($paras.slice(0, halfNumParas));
        let secondHalfHeight = tallyHeight($paras.slice(-halfNumParas));

        // Add padder to the end of p is...
        if (firstHalfHeight > secondHalfHeight) {
            // Add marker for inbalanced column to trigger css rules
            $copySection.addClass(toggleClass);

            // Alternative technique using spacer
            // let $spacer = $('<p>')
            //     .addClass('.copy-section__p-spacer')
            //     .height($paras.first().height())
            //     .appendTo($article);
        }
        else {
            $copySection.removeClass(toggleClass);
        }

      });
    }
}
