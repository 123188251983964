// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import ScrollController from './scroll/controller';
import Video from '../_modules/video/video';
import LogoSection from '../_modules/logo-section/logo-section';
import CopySection from '../_modules/copy-section/copy-section';
import Menu from '../_modules/menu/menu';
import ScrollHint from '../_modules/scroll-hint/scroll-hint';

$(() => {

	// Init scroll/animation controller
	let scrollController = new ScrollController([
		new Video(),
		new LogoSection()
	]);

	// Init other js components
	new CopySection(scrollController.controller);
	new Menu(scrollController.controller);
	new ScrollHint(scrollController.controller);

});
