// Base class for Scroll magic scenes
export default class ScrollScene {

    constructor(scene, controller) {

      // Scene can be instance of ScrollMagic.Scene or Array thereof as per ScrollMagic.addScene input
      this._scene = scene;

      // Add to controller if passed
      if (controller)
        controller.addScene(this.scene);
    }

  	get scene() {
  		return this._scene || [];
  	}

  	set scene(scene) {
  		this._scene = scene;
  	}
}
