'use strict';

import $ from 'jquery';
import ScrollMagic from '../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import ScrollScene from '../scroll-scene/scroll-scene';

export default class ScrollHint extends ScrollScene {

    constructor(controller) {

        var $scrollHint = $('.scroll-hint');
        var toggleClass = 'scroll-hint--hidden';

        super(
          new ScrollMagic.Scene({
            duration: 1,
            offset: 20,             //window.logoAnimationDuration +
          }).on('enter', (event) => {
            if (event.scrollDirection == 'FORWARD')
              $scrollHint.addClass(toggleClass);
            else
              $scrollHint.removeClass(toggleClass);
          })
        , controller);

        $scrollHint.on('click', (event) => {
          controller.scrollTo(window.logoAnimationDuration);
        });
    }

}
