'use strict';

import $ from 'jquery';
import ScrollMagic from '../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import NaturalScroll from '../../../bower_components/natural-scroll/naturalScroll';

export default class ScrollController {

	constructor(sceneModules) {

		// Create controller
		this.controller = new ScrollMagic.Controller({
			loglevel: 0,
			// refreshInterval: 0,
			globalSceneOptions: {
				loglevel: 0,
			},
		});

		// Attach scenes
		for (var module of sceneModules) {
			this.controller.addScene(module.scene);
		}

		// Check for window resize, recalculate
		window.onresize = () => {
			this.controller.update(true);
		}

		// Modify scrollTo behaviour
		this.controller.scrollTo((target) => {
			NaturalScroll.scrollTop(document.body, target);
		});
	}
}