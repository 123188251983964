'use strict';

import $ from 'jquery';
import ScrollMagic from '../../../node_modules/scrollmagic/scrollmagic/uncompressed/ScrollMagic';

export default class Menu {

    constructor(controller) {

      var $menu = $('#menu');
      var $menuItems = $menu.find('.main-menu__item');
      var topHref = $menuItems.eq(0).find('a').attr('href');
      var modifierClass = 'main-menu--hidden-disabled';
      var selectedClass = 'main-menu__item--selected';
      var sectionScenes = [];
      var sectionCache;
      var sectionCacheID;
      var $sectionChildrenCache;
      var sectionChildrenOffsetCache;
      var sectionChildrenOpacityCache;
      var windowHeightCache = window.innerHeight;

      var updateOffsets = function() {
        sectionChildrenOffsetCache = [];
        sectionChildrenOpacityCache = [];
        for (var i = 0; i < $sectionChildrenCache.length; i++) {
          sectionChildrenOffsetCache[i] = $sectionChildrenCache[i].offsetTop + $sectionChildrenCache[i].offsetHeight / 2;
        }
      }
      var getWindowHeightCache = function() {
        return windowHeightCache;
      };

      // Add scene to scroll controller
      controller.addScene(new ScrollMagic.Scene({
          duration: 300,
          offset: 10
        })
        .on('enter', (event) => {
          if (event.scrollDirection == 'FORWARD')
            $menu.removeClass(modifierClass);
          else
            $menu.addClass(modifierClass);
        })
        .on('leave', (event) => {
          if (event.scrollDirection == 'REVERSE')
            $menu.addClass(modifierClass);
          else
            $menu.removeClass(modifierClass);
        })
      );

      // Bind scroll to anchor links
      // Adapted from https://github.com/janpaepke/ScrollMagic/wiki/Tutorial-:-Anchor-Navigation
      $(document).on('click', '.main-menu__link', function(event) {
        let id = $(this).attr('href');
        event.preventDefault();
        controller.scrollTo(id == topHref ? window.logoAnimationDuration : id);
      });

      // Set up waypoints to trigger menu selected
      $menuItems.each((i, el) => {

        var scene;
        let id = el.getAttribute('data-item-id');
        let section = document.getElementById(id);

        if (i == 0) {
          scene = new ScrollMagic.Scene({
              offset: 0,
            });
        }
        else if (section) {
          scene = new ScrollMagic.Scene({
              triggerElement: section,
            });
        }

        if (scene) {
          scene.duration(getWindowHeightCache);
          scene.triggerHook(0.35);

          scene
            .on('enter', (event) => {
              let triggerElement = event.target.triggerElement();
              let menuClass = triggerElement ? triggerElement.id : 'reel';
              $menuItems
                .removeClass(selectedClass)
                .filter('.' + menuClass)
                  .addClass(selectedClass);
            })
            // Strictly, these events should be in copy-section.js since they affect the contents in that [pseudo-]'module'
            // However, that would introduce redundancy and since we are pushing the performance as it is........
            .on('progress', (event) => {
              let triggerElement = $(event.target.triggerElement());

              if (triggerElement.length) {
                if (triggerElement[0] != sectionCache) {
                  // Cache each child - have removed references to elements not used so will require updating
                  // if elements other than those listed are in template
                  sectionCache = triggerElement[0];
                  $sectionChildrenCache = triggerElement.find('h2, h3, h4, p');

                  // Store children offsets (these don't change unless resizing the window)
                  updateOffsets();
                }

                // Fade out children as they approach the horizon
                for (var i = 0; i < $sectionChildrenCache.length; i++) {
                  let opacity = Math.max(0.1, Math.min(1, (sectionChildrenOffsetCache[i] - window.scrollY + 25) / 175));

                  // Cache and compare value to avoid unneccessary DOM / style updates when opacity remains the same
                  if (opacity != sectionChildrenOpacityCache[i]) {
                    $sectionChildrenCache[i].style.opacity = sectionChildrenOpacityCache[i] = opacity;
                  }
                }
              }
            });

          sectionScenes.push(scene);
          controller.addScene(scene);
        }

      });

      $(window).on('resize', () => {
        windowHeightCache = window.innerHeight;
        updateOffsets();
      });

    }
}
